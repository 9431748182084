

import store from "@/store/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useAlert from '@/composables/Alert.ts';
import { getvoltarstatus } from "@/services/OficinaDigital";
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import LogEtapasModal from "@/components/modals/OficinaDigitalModals/LogEtapasModal.vue";
import { voltarParaInicioChecklist, voltarOrcamentoComplementarChecklist, finalizarChecklist, entrarEmContato, excluirChecklist, concessionariaAnexo } from "@/services/HistoricoChecklistService";
import { onMounted, ref, watch } from '@vue/runtime-core';
import { Modal } from "bootstrap";
import { hideModal } from '@/core/helpers/dom';
import AuthService from "@/services/AuthService";
import ModalAnexos from "@/views/historico-checklist/components/ModalAnexos.vue";
import ModalOS from "@/views/historico-checklist/components/ModalOS.vue";

export default {
    name: "historico-checklist-vermais-modal",
    props: {
        id: String,
        info: Object,
        referenciaStatusChecklist: Object,
        hiddenButtons: {
            type: Boolean,
            default: false
        }
    },
    emit: ["update"],
    components: {ModalAnexos, ModalOS, MessageModal, LogEtapasModal },

    setup(props, { emit }) {
      	const { showTimeAlert } = useAlert();
        const dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
        const modal:any = ref({});
        const logEtapasInfo:any = ref({});
        const modalWpp:any = ref({});
        const telefoneModel:any = ref("");
        const motivoModel:any = ref("");
        const messageModel = ref("");
        const loadingButtonSMS = ref(false);
        const loadingButtonWPP = ref(false);
        const userInfo = AuthService.getUsuarioAuth();
        const codNivel = userInfo.codNivel;
        const modalAnexos = ref();
        const modalOS = ref();
        const updateListAnexosActive = ref(true)
        //147 = renault Homologada
        //155 = hyundai Homologada
        const checklistAnexos = ref([])

        onMounted(async() => {
            const refModal = document.getElementById(`modal-${props.id}`);
            modal.value = refModal;

            const refModalWpp = document.getElementById(`wpp-${props.id}`);
            modalWpp.value = new Modal(refModalWpp);

            const modalAnexosID = document.getElementById('modal-anexos');
            const modalOSID = document.getElementById('modal-os');

            modalAnexos.value = new Modal(modalAnexosID);
            modalOS.value = new Modal(modalOSID);


            checklistAnexos.value = await concessionariaAnexo();
        });

        watch(() => props.info, () => {

            const telefone:string = props?.info?.telefone ?? "";
            telefoneModel.value = telefone;
        });

        async function voltarInicio(){
            Swal.fire({
                title: "Tem certeza que deseja voltar o checklist?",
                text: "O checklist irá retornar para o ínicio, essa ação não poderá ser desfeita.",
                position: 'center',
                showConfirmButton: true,
                reverseButtons: true,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-flex background_btn_1_opct text-white",
                    cancelButton: "btn btn-light me-3",
                },
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                focusConfirm: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try{
                        const response = await voltarParaInicioChecklist(props.info?.codConcessionaria, props.info?.checklist, props.info?.codCheckList)
                        showTimeAlert(response);
                        hideModal(modal.value);
                        emit("update");
                    } catch(e){
                        showTimeAlert("Não foi possível completar a solicitação, entre em contato com o nosso suporte!", "error");
                    }
                }
            });
        }

        function atualizaLogEtapas(){
            if(props.info.codCheckList != logEtapasInfo.value.codChecklist){
                logEtapasInfo.value = { ...props.info, codChecklist: props.info.codCheckList }
            }
        }

        async function voltarMecanico(){
            Swal.fire({
                title: "Tem certeza que deseja voltar o checklist?",
                text: "O checklist irá retornar para a etapa mecânico, essa ação não poderá ser desfeita.",
                position: 'center',
                showConfirmButton: true,
                reverseButtons: true,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-flex background_btn_1_opct text-white",
                    cancelButton: "btn btn-light me-3",
                },
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                focusConfirm: false,
            }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    await getvoltarstatus(props.info?.codCheckList, props.info?.codConcessionaria, props.info?.checklist);
                    showTimeAlert("O checklist voltou para o mecânico com sucesso!");
                    hideModal(modal.value);
                    emit("update");
                } catch(e){
                    showTimeAlert("Não foi possível completar a solicitação, entre em contato com o nosso suporte!", "error");
                }
            }});
        }

        async function voltarOrcamentoComplementar(){
            Swal.fire({
                title: "Tem certeza que deseja voltar o checklist?",
                text: "O checklist irá retornar para a etapa de orçamento complementar, essa ação não poderá ser desfeita.",
                position: 'center',
                showConfirmButton: true,
                reverseButtons: true,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-flex background_btn_1_opct text-white",
                    cancelButton: "btn btn-light me-3",
                },
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                focusConfirm: false,
            }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    await voltarOrcamentoComplementarChecklist(props.info?.codConcessionaria, props.info?.checklist, props.info?.codCheckList);
                    showTimeAlert("O checklist voltou para o orçamento complementar com sucesso!");
                    hideModal(modal.value);
                    emit("update");
                } catch(e){
                    showTimeAlert("Não foi possível completar a solicitação, entre em contato com o nosso suporte!", "error");
                }
            }});
        };

        async function finalizar(){
            Swal.fire({
                title: "Tem certeza que deseja finalizar o checklist?",
                text: "O checklist irá ser finalizado, essa ação não poderá ser desfeita.",
                position: 'center',
                showConfirmButton: true,
                reverseButtons: true,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-flex background_btn_1_opct text-white",
                    cancelButton: "btn btn-light me-3",
                },
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                focusConfirm: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try{
                        await finalizarChecklist(props.info?.codConcessionaria, props.info?.checklist, props.info?.codCheckList);
                        showTimeAlert("O checklist foi finalizado com sucesso!");
                        hideModal(modal.value);
                        emit("update");
                    } catch(e){
                        showTimeAlert("Não foi possível completar a solicitação, entre em contato com o nosso suporte!", "error");
                    }
                }
            });
        };

        async function openWpp () {
            if(!telefoneModel.value){
                showTimeAlert('Necessário preencher o telefone', 'error');
                return;
            }

            let telefone = telefoneModel.value;
            telefone = telefone.replaceAll(" ", ""); //retira espaços ex: (31)33333333
            telefone = telefone.replaceAll("-", ""); //retira espaços ex: (31)33333333
            telefone = telefone.replaceAll(")", ""); //retira parenteses ex: (3133333333
            telefone = telefone.replaceAll("(", ""); //retira

            const postData = {
                telefone,
                mensagem: messageModel.value ?? "",
                codConcessionaria: props.info?.codConcessionaria,
                codChecklist: props.info?.codCheckList,
                checklist: props.info?.checklist,
                tipo: 2,
            };
            
            loadingButtonWPP.value = true;
            try{
                const link = await entrarEmContato(postData);
                window.open(link);
                const elementModal = document.getElementById(`wpp-${props.id}`);
                hideModal(elementModal);
            } catch (e){
                showTimeAlert('Ocorreu um erro ao abrir o whatsapp', 'error');
            }
            loadingButtonWPP.value = false;            
        };

        async function excluir() {
            try{
                await excluirChecklist(props.info?.codConcessionaria, props.info?.checklist, props.info?.codCheckList, { motivo: motivoModel.value });
                showTimeAlert("O checklist foi excluído com sucesso!");
                const elementModal = document.getElementById(`excluir-${props.id}`);
                hideModal(elementModal);
                hideModal(modal.value);
                emit("update");
            } catch(e){
                showTimeAlert("Não foi possível completar a solicitação, entre em contato com o nosso suporte!", "error");
            }
        }

        async function enviarSMS() {
            if(!telefoneModel.value){
                showTimeAlert('Necessário preencher o telefone', 'error');
                return;
            }

            if(!messageModel.value){
                showTimeAlert('Necessário preencher a menssagem', 'error');
                return;
            }

            let telefone = telefoneModel.value;
            telefone = telefone.replaceAll(" ", ""); //retira espaços ex: (31)33333333
            telefone = telefone.replaceAll("-", ""); //retira espaços ex: (31)33333333
            telefone = telefone.replaceAll(")", ""); //retira parenteses ex: (3133333333
            telefone = telefone.replaceAll("(", ""); //retira

            const postData = {
                telefone,
                mensagem: messageModel.value ?? "",
                codConcessionaria: props.info?.codConcessionaria,
                codChecklist: props.info?.codCheckList,
                checklist: props.info?.checklist,
                tipo: 1,
            };
            
            loadingButtonSMS.value = true;
            try{
                await entrarEmContato(postData);
                const elementModal = document.getElementById(`sms-${props.id}`);
                hideModal(elementModal);
                showTimeAlert('O SMS foi enviado!');
            } catch (e){
                showTimeAlert('Ocorreu um erro ao enviar o SMS', 'error');
            }
            loadingButtonSMS.value = false;            
        };

        const updateListAnexos = () => {
          updateListAnexosActive.value = !updateListAnexosActive.value
        }

        return {
            codNivel,
            dominio_orcamento,
            telefoneModel,
            motivoModel,
            voltarMecanico,
            voltarInicio,
            voltarOrcamentoComplementar,
            finalizar,
            openWpp,
            enviarSMS,
            excluir,
            logEtapasInfo,
            atualizaLogEtapas,
            modalAnexos,
            modalOS,
            updateListAnexos,
            updateListAnexosActive,
            checklistAnexos
        }
  }
};
