
import { ContentLoader } from 'vue-content-loader';
import { defineComponent, onMounted, ref, watch } from "vue";
import ApexChart from "apexcharts";

export default defineComponent({
  name: "MotivosReprovacaoCard",
  components: { ContentLoader },
  props: {
    loadingTable: Boolean,
    data: Array,
  },
  setup(props) {

    // const labels = ["Desejo negociar", "Preço", "Não desejo", "Realizar em outra loja", "Realizar na próxima", "Garantia"]
    // const colors = ["#22B9FF", "#2BACE8", "#34A5D9", "#3F9DC8", "#4991B2", "#477B93", "#4B6E7D", "#79A8BD", "#A2CDE1", "#d3f2ff", "#6674ff", "#727cf4", "#7c85ed", "#878ee4", "#336666", "#9095d9", "#8f92c7", "#9295b8", "#b6b8df", "#cfd1f1", "#6674ff", "#6674ff", "#7c85ed", "#878ee4", "#9095d9", "#8f92c7", "#9295b8", "#b6b8df", "#cfd1f1", "#d3d6ff", "#b766ff", "#b472f4", "#b387e4", "#b190d9", "#a88fc7", "#a292b8", "#c8b6df", "#decff1", "#e6d3ff"];
    const colors = ['#e69f84', '#4b1187', '#a83443', '#9beec8', '#fbc38b', '#f334dd', '#3f5b59', '#ac3a66', '#a4460a', '#33e93d', '#ef47e9', '#1eeff5', '#127c6d', '#3372b5', '#be9cd5', '#12b446', '#8eef14', '#42a325', '#8f0c81', '#45639e', '#9a648b', '#c1dada', '#352f35', '#4c392c', '#a6a63c', '#d90705', '#a8ad5c', '#ea5aeb', '#2e8f81', '#25e2c6', '#3f2b61', '#e8fb18', '#c90cb3', '#db21ac', '#c3e335', '#cf0a1e', '#4ca28b'];
    const labels:any = ref([]);
    const series:any = ref([]);
    //declaração de variaveis//
    let chartInstance;
    let chartMobileInstance;
    const image = "https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/shapes/abstract-1.svg";
 
    function geraOptions(labelsRef, refColors, isMobile = false) {
      const newOptions = {
        dataLabels: {
          enabled: true,
        },
        chart: {
          width: '100%',
          height: 230,
          type: 'donut',
          options: "chart",
          offsetY: -10,
          series: [],
        },
        stroke: {
          width: 0
        },
        legend: {
          horizontalAlign: 'left', 
          position: isMobile ? 'bottom' : 'right'
        },
        labels: labelsRef,
        colors: refColors,
        fill: {
          colors: refColors,
        },
      }

      return newOptions;
    }

    watch(() => props.data, () => {
      if(props.data){
        console.log("data do componente", props.data)
        const motivos:Array<any> = [...props.data];
        const motivosWithValue = motivos.filter((motivo, index) => motivo.quantidade > 0);

        series.value = motivosWithValue.map(motivo => motivo.quantidade);
        labels.value = motivosWithValue.map(motivo => {
          let nomeTratado = motivo.nome[0].toUpperCase() + motivo.nome.slice(1).toLowerCase();
          return nomeTratado;
        });

        let colorsData:any = [];
        motivos.forEach((motivo, index) => {
          if(motivo.quantidade > 0){
            colorsData.push(colors[index]);
          }
        });

        setTimeout(() => {
          chartInstance.updateOptions(geraOptions(labels.value, colorsData));
          chartInstance.updateSeries(series.value);
          chartMobileInstance.updateOptions(geraOptions(labels.value, colorsData, true));
          chartMobileInstance.updateSeries(series.value);
          resizeWithTime();
        }, 500);
      }
    });
  
    onMounted(async () => {
      const options = geraOptions([""], ["#22B9FF"]);
      chartInstance = new ApexChart(document.querySelector("#grafico-motivo"), {
        ...options,
        series: [0, 0, 0, 0, 0],
      });
      chartMobileInstance = new ApexChart(document.querySelector("#grafico-mobile-motivo"), {
        ...options,
        series: [0, 0, 0, 0, 0],
      });
      chartInstance.render();
      chartMobileInstance.render();
    });

    function resizeWithTime(time = 100){
      setTimeout(() => window.dispatchEvent(new Event('resize')), time)
    }

    return {
      image,
      colors,
      labels,
      series,
    };
  },
});
