
import {onMounted, reactive, ref, watch} from "vue";
import {Modal} from "bootstrap";

export default {
  props: {
    checklist: String,
    codCheckList: String,
    listaOS: Object,
    classModal: {
			type: String,
			default: "",
		},
  },
  setup(prop) {
    const modalOS = ref()
    const payload = {
      checklist: 0,
      codChecklist: 0,
      listaOS: [],
    }
    const loading = ref(false)
    const buttonLoading = ref(true)

    onMounted(() => {
      const modalOSID = document.getElementById('modal-os');
      modalOS.value = new Modal(modalOSID);
    })

    watch(() => [prop.checklist, prop.codCheckList, prop.listaOS], () => {

      console.log("Aqui");
      console.log(prop.listaOS);
      payload.checklist = prop.checklist
      payload.codChecklist = prop.codCheckList
      payload.listaOS = prop.listaOS
      
    
    })

    

    return {
      loading,   
      buttonLoading,
    }
  }
}
